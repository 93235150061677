import { Fragment, useState } from "react"
import { Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/solid"
import { useToast } from "../context/ToastContext"

interface Props {
  id: number
  title: string
  body?: string
  icon?: any
}

/**
 * Usage: Import { addToast } from useToast
 * useToast({ title, body, icon: <Icon className="h-6 w-6 text-red-500" ariaHidden={true} /> })
 */

const Notification = ({ id, title, body, ...rest }: Props) => {
  const { removeToast } = useToast()
  const [show, setShow] = useState(true)

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0">
      <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="p-4">
          <div className="flex items-start">
            {rest.icon ? <div className="flex-shrink-0">{rest.icon}</div> : null}
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900">{title}</p>
              {body ? <p className="mt-1 text-sm text-gray-500">{body}</p> : null}
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              <button
                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                onClick={() => {
                  setShow(false)
                  removeToast && removeToast(id)
                }}>
                <span className="sr-only">Close</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  )
}

export default Notification
