import type { AppProps } from "next/app"
import Router, { useRouter } from "next/router"
import NPogress from "nprogress"
import Layout from "../modules/layout/components/Layout"
import * as ga from "../modules/common/lib/googleAnalytics"
import { Provider as AuthProvider, useSession } from "next-auth/client"

import "../modules/common/styles/globals.css"
import "../modules/common/styles/nprogress.css"
import { useEffect, useState } from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { ToastProvider } from "../modules/common/context/ToastContext"

Router.events.on("routeChangeStart", () => NPogress.start())
Router.events.on("routeChangeComplete", (url) => {
  ga.pageview(url)
  NPogress.done()
})
Router.events.on("routeChangeError", () => NPogress.done())

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PK!)

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <AuthProvider session={pageProps.session}>
      <ToastProvider>
        <Elements stripe={stripePromise}>
          <Layout>
            {/* @ts-ignore */}
            {Component.authRequired ? (
              <>
                {/* @ts-ignore */}
                <Auth authRequired={Component.authRequired}>
                  <Component {...pageProps} />
                </Auth>
              </>
            ) : (
              <Component {...pageProps} />
            )}
          </Layout>
        </Elements>
      </ToastProvider>
    </AuthProvider>
  )
}

export default MyApp

function Auth({ authRequired, children }) {
  const [session, loading] = useSession()
  const [canRender, setCanRender] = useState(false)
  const isUser = !!session?.user
  const router = useRouter()

  useEffect(() => {
    // 1. Loading - do nothing
    if (loading) return
    // 2. No user - force log in
    if (!isUser || !session) {
      router.push("/signin")
      return
    }

    // 3. Subscription - force create subscription
    // Track in this GH discussion: https://github.com/nextauthjs/next-auth/issues/2269
    const localStatus = localStorage.getItem("SUBSCRIPTION_STATUS")
    if ((!localStatus || localStatus === "NONE") && session.subscriptionStatus === "NONE") {
      router.push("/signup?step=2")
      return
    }
    // TODO: Other subscription page when subscription is !NONE and !ACTIVE
    // TODO: Type subscriptionStatus

    // 4. Check role if applicable
    if (typeof authRequired === "object" && authRequired.validRoles) {
      if (!authRequired.validRoles.some((item) => item === session.role)) {
        router.back()
        return
      }
    }
    setCanRender(true)
  }, [isUser, loading, router, session, authRequired])

  if (canRender) {
    return children
  }

  // Session is being fetched, or no user.
  // If no user, useEffect() will redirect.
  return <div className="px-4 py-3">Loading...</div>
}
