import { useRouter } from "next/router"
import Nav from "./Nav"
import AppNav from "./AppNav"

export default function Layout({ children }) {
  const router = useRouter()
  const isMarketing =
    /contact|contact-success|signin|signup|posts|support|feedback|privacy|terms|faq/.test(
      router.pathname,
    ) || router.pathname === "/"
  const isError = /404|500/.test(router.pathname)
  const isHome = router.pathname === "/"

  if (isMarketing || isError || isHome) {
    return (
      <div className={`bg-gray-50 min-h-screen flex flex-col h-full overflow-y-auto`}>
        <Nav />
        {children}
      </div>
    )
  }

  return (
    <div className={`h-full`}>
      <AppNav>{children}</AppNav>
    </div>
  )
}
