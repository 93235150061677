import { Dialog, Transition } from "@headlessui/react"
import Link from "next/link"
import { Fragment, useEffect, useState } from "react"
import {
  HomeIcon,
  QuestionMarkCircleIcon,
  MenuIcon,
  ChatAltIcon,
  XIcon,
  DocumentReportIcon,
  LogoutIcon,
  UserIcon,
} from "@heroicons/react/outline"
import { signOut, useSession } from "next-auth/client"
import { Role } from "@prisma/client"
import { useRouter } from "next/router"
import Image from "next/image"

const AppNav = ({ children }) => {
  const router = useRouter()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [session, loading] = useSession()
  const user = session?.user
  const [hasProfilePhoto, setHasProfilePhoto] = useState<boolean>(loading || !!user?.image)

  const navigation = [{ name: "Dashboard", href: "/dashboard", type: "link", icon: HomeIcon }]

  const secondaryNav = [
    { name: "Feedback", icon: ChatAltIcon, href: "/feedback", type: "external" },
    { name: "Support", icon: QuestionMarkCircleIcon, href: "/support", type: "external" },
  ]

  if (session?.role === Role.SUPER_ADMIN) {
    navigation.push({
      name: "Admin Tools",
      href: "/admin-tools",
      icon: DocumentReportIcon,
      type: "link",
    })
  }

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => setMobileMenuOpen(false)
    router.events.on("routeChangeStart", handleRouteChange)

    return () => {
      router.events.off("routeChangeStart", handleRouteChange)
    }
  }, [router.events])

  return (
    <>
      <div className="h-full flex">
        {/* Mobile Menu */}
        <Transition.Root show={mobileMenuOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 md:hidden"
            onClose={setMobileMenuOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full">
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <div className="absolute top-0 right-0 -mr-12 pt-4">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setMobileMenuOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="py-4">
                  <div className="flex-shrink-0 flex items-center px-4">
                    <span className="text-2xl text-primary-600 font-bold">Skillfyre</span>
                  </div>
                  <nav aria-label="Sidebar" className="mt-4">
                    <div className="px-2 space-y-1">
                      {navigation.map((item) => (
                        <Link key={item.name} href={item.href}>
                          <a
                            className={`group p-2 rounded-md flex items-center text-base font-medium hover:bg-gray-50 hover:text-gray-900 ${
                              router.asPath === item.href
                                ? "bg-gray-50 text-gray-900"
                                : "text-gray-600"
                            }`}>
                            <item.icon
                              className={`mr-4 h-6 w-6 ${
                                router.asPath === item.href ? "text-gray-500" : "text-gray-400"
                              } group-hover:text-gray-500`}
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        </Link>
                      ))}
                    </div>
                  </nav>
                </div>

                <nav aria-label="Sidebar" className="border-t border-gray-200 py-4">
                  <div className="px-2 space-y-1">
                    {secondaryNav.map((item) =>
                      item.type === "external" ? (
                        <a
                          key={item.name}
                          href={item.href}
                          target="_blank"
                          rel="noreferrer"
                          className="group p-2 rounded-md flex items-center text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900">
                          <item.icon
                            className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ) : (
                        <Link key={item.name} href={item.href}>
                          <a className="group p-2 rounded-md flex items-center text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900">
                            <item.icon
                              className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        </Link>
                      ),
                    )}

                    <button
                      onClick={() => {
                        signOut()
                      }}
                      className="w-full group p-2 rounded-md flex items-center text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900">
                      <LogoutIcon
                        className="mr-4 h-6 w-6 ml-1 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      <span>Logout</span>
                    </button>
                  </div>
                </nav>
                <div className="flex-shrink-0 flex p-4 pt-0">
                  <Link href="/account">
                    <a className="flex-shrink-0 group block">
                      <div className="flex items-center">
                        <div>
                          {hasProfilePhoto ? (
                            <img
                              className="inline-block h-10 w-10 rounded-full"
                              src={user?.image || ""}
                              alt=""
                              referrerPolicy="no-referrer"
                              onError={() => setHasProfilePhoto(false)}
                            />
                          ) : (
                            <UserIcon className="inline-block h-6 w-6 text-gray-400" />
                          )}
                        </div>
                        <div className="ml-3">
                          <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                            {user?.name}
                          </p>
                          <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                            Account Settings
                          </p>
                        </div>
                      </div>
                    </a>
                  </Link>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:flex-shrink-0">
          <div className="flex flex-col w-20">
            <div className="flex-1 flex flex-col min-h-0 overflow-y-auto bg-primary-600">
              <div className="flex-1">
                <div className="bg-primary-700 py-4 flex items-center justify-center">
                  <Image height={32} width={32} src="/assets/Icon_White.svg" />
                </div>
                <nav aria-label="Sidebar" className="py-4 flex flex-col items-center space-y-2">
                  {navigation.map((item) => (
                    <Link href={item.href} key={item.name}>
                      <a
                        className={`flex flex-col items-center p-4 rounded-lg text-primary-200 hover:bg-primary-700 ${
                          router.asPath === item.href ? "bg-primary-700" : ""
                        }`}>
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                        <span className="sr-only">{item.name}</span>
                      </a>
                    </Link>
                  ))}
                </nav>
              </div>

              <nav
                aria-label="Sidebar"
                className="py-4 flex flex-col items-center space-y-2 border-t border-primary-400">
                {secondaryNav.map((item) =>
                  item.type === "external" ? (
                    <a
                      href={item.href}
                      key={item.name}
                      target="_blank"
                      rel="noreferrer"
                      className="flex items-center p-4 rounded-lg text-primary-200 hover:bg-primary-700">
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                      <span className="sr-only">{item.name}</span>
                    </a>
                  ) : (
                    <Link href={item.href} key={item.name}>
                      <a className="flex items-center p-4 rounded-lg text-primary-200 hover:bg-primary-700">
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                        <span className="sr-only">{item.name}</span>
                      </a>
                    </Link>
                  ),
                )}
                <button
                  onClick={() => {
                    signOut()
                  }}
                  className="flex items-center p-4 rounded-lg text-primary-200 hover:bg-primary-700">
                  <LogoutIcon className="h-6 w-6 -mr-1" aria-hidden="true" />
                  <span className="sr-only">Logout</span>
                </button>
              </nav>

              <div className="flex-shrink-0 flex pb-5">
                <Link href="/account">
                  <a className="flex-shrink-0 w-full">
                    {hasProfilePhoto ? (
                      <img
                        className="block mx-auto h-10 w-10 rounded-full"
                        src={user?.image || ""}
                        referrerPolicy="no-referrer"
                        alt=""
                        onError={() => setHasProfilePhoto(false)}
                      />
                    ) : (
                      <UserIcon className="h-14 w-14 -mt-4 p-4 text-primary-200 mx-auto hover:bg-primary-700 rounded-lg" />
                    )}
                    <div className="sr-only">
                      <p>{user?.name}</p>
                      <p>Account settings</p>
                    </div>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-1 min-w-0 flex flex-col overflow-hidden">
          {/* Mobile top navigation */}
          <div className="md:hidden">
            <div className="bg-primary-600 py-1 px-4 flex items-center justify-between sm:px-6">
              <div className="text-white text-xl font-semibold tracking-wide">Skillfyre</div>
              <div>
                <button
                  type="button"
                  className="-mr-3 h-12 w-12 inline-flex items-center justify-center bg-primary-600 rounded-md text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setMobileMenuOpen(true)}>
                  <span className="sr-only">Open sidebar</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
          {children}
        </div>
      </div>
    </>
  )
}

export default AppNav
