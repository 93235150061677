// log the pageview with their URL
export const pageview = (url: string) => {
  if (process.env.NODE_ENV !== "production") return
  // @ts-ignore
  window.gtag("config", process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
    page_path: url,
  })
}

// log specific events happening.
export const logAnalyticsEvent = ({
  action,
  params,
}: {
  action: AnalyticsEvents
  params: { [key: string]: any }
}) => {
  if (process.env.NODE_ENV !== "production") return

  // @ts-ignore
  window.gtag("event", action, params)
}

export type AnalyticsEvents = "EmailSignupSubmit"
