import { createContext, useContext, useEffect, useState } from "react"
import Notification from "../components/Notification"

interface Toast {
  id?: number
  title: string
  body?: string
  icon?: any
}

interface Props {
  toasts: Toast[]
  addToast: (toast: Toast) => void
  removeToast: (id: number) => void
}

const ToastContext = createContext<Partial<Props>>({})
const { Provider } = ToastContext

const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState<Toast[]>([])

  useEffect(() => {
    if (toasts.length > 0) {
      // Remove one toast each 4 seconds
      const timer = setTimeout(() => {
        setToasts((toasts) => toasts.slice(1))
      }, 4000)
      return () => clearTimeout(timer)
    }
  }, [toasts])

  const addToast = (toast: Toast) => {
    if (!toast.id) {
      toast.id = Date.now()
    }
    setToasts((toasts) => [...toasts, toast])
  }

  const removeToast = (id: number) => {
    const filteredToasts = toasts.filter((t) => t.id && t.id !== id)
    setToasts(filteredToasts)
  }

  return (
    <Provider
      value={{
        toasts,
        addToast,
        removeToast,
      }}>
      {children}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {toasts.reverse().map((t) => (
            <Notification id={t.id!} title={t.title} body={t.body} icon={t.icon} key={t.id} />
          ))}
        </div>
      </div>
    </Provider>
  )
}

export function useToast() {
  const context = useContext(ToastContext)

  if (!context) {
    throw Error("The `useToast` hook must be called from a descendent of the `ToastProvider`.")
  }
  return context
}

export { ToastContext, ToastProvider }
