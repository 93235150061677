import { Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"
import Link from "next/link"
import Image from "next/image"
import { useRouter } from "next/router"
import { useSession } from "next-auth/client"

interface Link {
  name: string
  href?: string
  action?: Function
  style: "link" | "btn"
  type: "link" | "btn"
}

export default function Nav() {
  const router = useRouter()
  const [session] = useSession()

  let navigation: Link[] = []
  // if (!router.pathname.startsWith("/signup")) {
  //   navigation.push({ name: "Sign up", href: "/signup", type: "link", style: "link" })
  // }
  // if (!router.pathname.startsWith("/signin")) {
  //   navigation.push({ name: "Sign in", href: "/signin", type: "link", style: "link" })
  // }
  navigation.unshift(
    { name: "About", href: "/#about", type: "link", style: "link" },
    // { name: "Contact", href: "/contact", type: "link", style: "link" },
    {
      name: "Sign up",
      href: "https://docs.google.com/forms/d/e/1FAIpQLScRrMnrwv2h3aPrfYRZeTdG05TqfHt_xywpxZMpdsQdnWwV-Q/viewform?usp=sf_link",
      type: "link",
      style: "link",
    },
  )

  if (session) {
    navigation.push({ name: "Dashboard", href: "/dashboard", type: "link", style: "btn" })
    navigation = navigation.filter(
      (navItem) => !["/signin", "/signup"].includes(navItem.href || ""),
    )
  }

  return (
    <Popover className="relative z-10">
      {({ open }) => (
        <>
          <div className="relative py-6 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
            <nav className="relative flex items-center justify-between sm:h-10" aria-label="Global">
              <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <Link href="/">
                    <a className="flex items-center">
                      <Image width={32} height={32} src="/assets/Icon.svg" />
                      <span className="text-2xl font-extrabold tracking-wide -ml-1 text-primary-600 hover:text-primary-500">
                        Skillfyre
                      </span>
                    </a>
                  </Link>
                  <div className="-mr-2 flex items-center md:hidden">
                    <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                      <span className="sr-only">Open main menu</span>
                      <MenuIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                {navigation.map((item) =>
                  item.type === "link" ? (
                    <Link key={item.name} href={item.href || ""}>
                      <a
                        className={`${
                          item.style === "btn"
                            ? "btn"
                            : `font-medium ${
                                router.pathname === item.href
                                  ? "text-primary-600 hover:text-primary-700"
                                  : "text-gray-500 hover:text-gray-900"
                              }`
                        }`}>
                        {item.name}
                      </a>
                    </Link>
                  ) : item.type === "btn" ? (
                    <button
                      onClick={() => item.action && item.action()}
                      key={item.name}
                      className={`${
                        item.style === "btn"
                          ? "btn"
                          : "font-medium text-gray-500 hover:text-gray-900"
                      }`}>
                      {item.name}
                    </button>
                  ) : null,
                )}
              </div>
            </nav>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <Popover.Panel
              focus
              static
              className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
              <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="px-2 pt-4 flex items-center justify-between">
                  <Link href="/">
                    <a className="flex items-center">
                      <Image width={32} height={32} src="/assets/Icon.svg" />
                      <span className="text-xl font-extrabold tracking-wide -ml-1 text-primary-600 hover:text-primary-500">
                        Skillfyre
                      </span>
                    </a>
                  </Link>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                      <span className="sr-only">Close main menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="px-2 pt-2 pb-3 space-y-1">
                  {navigation.map((item) =>
                    item.type === "link" ? (
                      <Link key={item.name} href={item.href || ""}>
                        <a
                          className={`${
                            item.style === "btn"
                              ? "btn"
                              : "block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                          }`}>
                          {item.name}
                        </a>
                      </Link>
                    ) : item.type === "btn" ? (
                      <button
                        onClick={() => item.action && item.action()}
                        key={item.name}
                        className={`${
                          item.style === "btn"
                            ? "btn"
                            : "block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                        }`}>
                        {item.name}
                      </button>
                    ) : null,
                  )}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
